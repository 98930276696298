import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from '../modules/AbstractModule';

const MODULE_NAME = 'Popup';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLOSE: `close.${EVENT_NAMESPACE}`
};



export default class {
    constructor(options) {

        
        // Set events and such
        this.popupItems = {};
        this.popupBtnItems = {};
        this.popups = document.querySelectorAll('.js-popup');

        $document.on(EVENT.CLOSE, (event) => {
          return this.close();
        });


        var _loop = (i) => {
          var close = this.popups[i].querySelector('.c-popups_close');

          close.addEventListener('click', () => {
            this.close(this.popups[i].dataset.popup);
          });
          this.popupItems[this.popups[i].dataset.popup] = {
            element: this.popups[i],
            active: false
          };
        };

        for (var i = 0; i < this.popups.length; i++) {
          _loop(i);
        }

        this.btns = document.querySelectorAll('.js-popup-btn');

        var _loop2 = (i) => {
          this.popupBtnItems[this.btns[i].dataset.popup] = {
            element: this.btns[i],
            active: false
          };

          this.btns[i].addEventListener('click', () => {
            this.active(this.btns[i].dataset.popup);
          });
        };

        for (var i = 0; i < this.btns.length; i++) {
          _loop2(i);
        }

       


          
    }

   

 



    active(id) {
      if (!this.popupBtnItems[id].active) {
        for (var key in this.popupBtnItems) {
          if (id !== key) {
            this.popupBtnItems[key].active = false;
            this.popupItems[key].active = false;
            this.popupItems[key].element.classList.remove('is-active');
          }
        }

        this.popupBtnItems[id].active = true;
        this.popupItems[id].active = true;
        this.popupItems[id].element.classList.add('is-active');
      }
    }

    close() {
      for (var key in this.popupBtnItems) {
        this.popupBtnItems[key].active = false;
        this.popupItems[key].active = false;
        this.popupItems[key].element.classList.remove('is-active');
      }
    }

    destroy() {
        console.log('❌ [module]:destroy - Popup');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
