import {
  APP_NAME,
  $document
} from '../utils/environment';
import AbstractModule from './AbstractModule';
import 'owl.carousel';

const MODULE_NAME = 'Slider';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export default class extends AbstractModule {
  constructor(options) {
    super(options);
  }

  init() {

    $('#testi').owlCarousel({
      nav: false,
      items: 1,
      dots: true,
      loop: true,
      mouseDrag: true,
      autoHeight: true,
      margin: 30,
      
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          stagePadding: 300,
          items: 1
        }
      }
    });

  }

  destroy() {
    super.destroy();
  }
}