import { APP_NAME } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { TweenMax } from "gsap/TweenMax";


const MODULE_NAME = 'Cover';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    RESIZE: `resize.${EVENT_NAMESPACE}`

};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        console.log('🔨 [module]:constructor - Cover');

    }

    init() {
        // Set events and such
        this.$btn = $(this.el);
        this.$selector = $(this.el).find('.c-faq-min_item_click__JS');
        this.active = false;
        this.$selector.on(EVENT.CLICK, (event) => {
          if (!this.$el.hasClass('is-active')) {
            this.active = true;

            this.el.classList.add('is-active');

            TweenMax.to(this.bottom, 0.5, {
              height: this.bottomHeight + 50
            });
          } else {
            this.active = false;

            this.el.classList.remove('is-active');

            TweenMax.to(this.bottom, 0.5, {
              height: 0
            });
          }
        });
        this.bottom = this.el.querySelector('.c-faq-min_bottom');
        this.resize();
    }

    resize() {
      this.bottomHeight = this.bottom.children[0].offsetHeight;
    }



    destroy() {
        console.log('❌ [module]:destroy - Cover');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
